import {
  DEFAULT_POST_TYPE_ID,
  POST_TYPE_IDS_ENUM,
} from "../../../constants/BrandedCommunity/Post";
import {
  IPostDataInEditingFlow,
  IPostDataInFeed,
} from "../../../components/BrandedCommunity/Post/Post.types";
import {
  IUseDocInEditPostModalReturn,
  useDocInEditPostModal,
} from "./useDocInEditPostModal";
import {
  IUseImageInEditPostModalReturn,
  useImageInEditPostModal,
} from "./useImageInEditPostModal";
import {
  IUseOfferingInEditPostModalProps,
  IUseOfferingInEditPostModalReturn,
  useOfferingInEditPostModal,
} from "./useOfferingInEditPostModal";
import {
  IUseVideoInEditPostModalReturn,
  useVideoInEditPostModal,
} from "./useVideoInEditPostModal";
import { useEffect, useRef, useState } from "react";
import { IEditPostModalProps } from "../../../components/BrandedCommunity/EditPostModal/EditPostModal.types";
import { IUseMediaInEditPostModalProps } from "./useMediaInEditPostModal.types";
import { POST_SHARING_TYPES } from "../../../features/BrandedCommunity/constants/BrandedCommunity.constants";
import { checkIsDefined } from "../../../features/Common/modules/DataTypes/utils/nanNullUndef";

const POST_UPDATE_PAYLOAD_DEFAULT_VALUE: IPostDataInEditingFlow = {
  post_heading: "",
  post_body: "",
  post_type: DEFAULT_POST_TYPE_ID,
  metadata: {
    auto_expand: true,
  },
};

const CREATOR_POST_UPDATE_PAYLOAD_DEFAULT_VALUE: IPostDataInEditingFlow = {
  ...POST_UPDATE_PAYLOAD_DEFAULT_VALUE,
  // creator is allowed to change the post sharing type and customer's post sharing type will be determined by the creator in channel's settings
  metadata: {
    ...POST_UPDATE_PAYLOAD_DEFAULT_VALUE.metadata,
    post_sharing_type: POST_SHARING_TYPES.PUBLIC,
  },
};

export interface IUseEditPostModalProps {
  isOpen: IEditPostModalProps["isOpen"];
  isCreator?: boolean;
  onClose: () => void;
  postData?: IPostDataInFeed;
  disablePostTypeChange?: boolean;
  disablePostTypeDataEdit?: boolean;
  triggerPostTypeId?: number;
  onSaveButtonClick: (args: { payload: IPostDataInEditingFlow }) => void;
  onPostOfferingBuyNowClick: IUseOfferingInEditPostModalProps["onPostOfferingBuyNowClick"];
  notifyError: (message: string) => void;
  customCheckPostContentIsNotEmpty?: (postContent: string) => boolean;
  onEditPostTypeMediaClick: IUseMediaInEditPostModalProps["onEditPostTypeMediaClick"];
}

export interface IUseEditPostModalReturn {
  // props used by the modal directly
  isOpen: IEditPostModalProps["isOpen"];
  onClose: IEditPostModalProps["onClose"];
  postContentDefaultValue?: IEditPostModalProps["postContentDefaultValue"];
  postContentTextAreaRef: IEditPostModalProps["postContentTextAreaRef"];
  postMediaUploading: IEditPostModalProps["postMediaUploading"];
  postMediaIsAdded: IEditPostModalProps["postMediaIsAdded"];
  postImageProps: IEditPostModalProps["postImageProps"];
  postDocProps: IEditPostModalProps["postDocProps"];
  postVideoProps: IEditPostModalProps["postVideoProps"];
  postOfferingProps: IEditPostModalProps["postOfferingProps"];

  // props used by other components related to modal
  postData: IPostDataInEditingFlow;
  onSaveButtonClick: () => void;
  onChangeSharingType: (sharingType: number) => void;
  postTypeSelectorProps: {
    onPostTypeClick: IEditPostModalProps["postTypeSelectorProps"]["onPostTypeClick"];
    disablePostTypeClick: IEditPostModalProps["postTypeSelectorProps"]["disablePostTypeClick"];
    postTypeId: IEditPostModalProps["postTypeSelectorProps"]["postTypeId"];
    postMediaIsAdded: IEditPostModalProps["postTypeSelectorProps"]["postMediaIsAdded"];
  };
  autoExpandPostComponentProps: {
    onChange: (checked: boolean) => void;
    value: boolean;
  };
  imageSelectorProps: IUseImageInEditPostModalReturn["imageSelectorProps"];
  docSelectorProps: IUseDocInEditPostModalReturn["docSelectorProps"];
  videoSelectorProps: IUseVideoInEditPostModalReturn["videoSelectorProps"];
  offeringSelectorProps: IUseOfferingInEditPostModalReturn["offeringSelectorProps"];
}

export const useEditPostModal = ({
  isOpen,
  isCreator,
  onClose,
  postData: postDataProp,
  disablePostTypeChange: disablePostTypeChangeProp,
  disablePostTypeDataEdit,
  triggerPostTypeId: triggerPostTypeIdProp,
  onSaveButtonClick: onSaveButtonClickProp,
  onPostOfferingBuyNowClick,
  notifyError,
  customCheckPostContentIsNotEmpty: customCheckPostContentIsNotEmpty,
  onEditPostTypeMediaClick,
}: IUseEditPostModalProps): IUseEditPostModalReturn => {
  const postContentTextAreaRef = useRef<HTMLTextAreaElement | null>(null);

  /**
   * This value tells the triggered post type id.
   * If value is set, the respective post type child modal will open initially.
   * If the child modal is closed directly the base modal(edit post modal) will be closed as well.
   */
  const [triggerPostTypeId, setTriggerPostTypeId] = useState<
    number | undefined
  >(undefined);

  const onCloseSelectorWithoutSelecting = () => {
    if (
      [
        POST_TYPE_IDS_ENUM.IMAGE,
        POST_TYPE_IDS_ENUM.VIDEO,
        POST_TYPE_IDS_ENUM.DOCUMENT,
        POST_TYPE_IDS_ENUM.OFFERING,
      ].includes(triggerPostTypeId!)
    ) {
      onClose();
    }
  };
  const onAddPostTypeMediaSuccess = () => {
    // so that further editing of post type media does not trigger the onClose of the EditPostModal
    if (typeof triggerPostTypeId === "number") {
      setTriggerPostTypeId(undefined);
    }
  };

  const [postData, setPostData] = useState<IPostDataInEditingFlow>(
    postDataProp ||
      (isCreator
        ? CREATOR_POST_UPDATE_PAYLOAD_DEFAULT_VALUE
        : POST_UPDATE_PAYLOAD_DEFAULT_VALUE),
  );

  const onSaveButtonClick = () => {
    let postContent = "";
    if (postContentTextAreaRef?.current) {
      postContent = postContentTextAreaRef.current.value || "";
    }

    if (
      postTypeId === POST_TYPE_IDS_ENUM.TEXT &&
      (customCheckPostContentIsNotEmpty
        ? !customCheckPostContentIsNotEmpty(postContent)
        : !postContent.trim())
    ) {
      notifyError("Post connot be empty!");
      return;
    }

    const payload: IPostDataInEditingFlow = {
      ...postData,
      post_body: postContent,
    };
    delete payload.listing_details; // we get listing details in the get api for rendering at front end... its not actually a part of the post data

    onSaveButtonClickProp({ payload });
  };

  const resetPostTypeToText = () => {
    setPostData((prev) => ({
      ...prev,
      post_type: DEFAULT_POST_TYPE_ID,
      post_listing_uuid: undefined,
      listing_details: undefined,
      metadata: {
        auto_expand: prev.metadata?.auto_expand ?? true,
        ...(checkIsDefined(prev?.metadata?.post_sharing_type)
          ? { post_sharing_type: prev.metadata?.post_sharing_type }
          : undefined),
      },
    }));
  };

  const onRemovePostTypeMedia = resetPostTypeToText;

  const { onAddPostTypeImageClick, postImageProps, imageSelectorProps } =
    useImageInEditPostModal({
      postData,
      setPostData,
      disablePostTypeDataEdit,
      onRemovePostTypeMedia,
      onCloseSelectorWithoutSelecting,
      onAddPostTypeMediaSuccess,
      onEditPostTypeMediaClick,
    });

  const { onAddPostTypeDocClick, postDocProps, docSelectorProps } =
    useDocInEditPostModal({
      postData,
      setPostData,
      disablePostTypeDataEdit,
      onRemovePostTypeMedia,
      onCloseSelectorWithoutSelecting,
      onAddPostTypeMediaSuccess,
      onEditPostTypeMediaClick,
    });

  const { onAddPostTypeVideoClick, postVideoProps, videoSelectorProps } =
    useVideoInEditPostModal({
      postData,
      setPostData,
      disablePostTypeDataEdit,
      onRemovePostTypeMedia,
      onCloseSelectorWithoutSelecting,
      onAddPostTypeMediaSuccess,
      onEditPostTypeMediaClick,
    });

  const {
    onAddPostTypeOfferingClick,
    postOfferingProps,
    offeringSelectorProps,
  } = useOfferingInEditPostModal({
    postData,
    setPostData,
    disablePostTypeDataEdit,
    onRemovePostTypeMedia,
    onCloseSelectorWithoutSelecting,
    onAddPostTypeMediaSuccess,
    onPostOfferingBuyNowClick,
    onEditPostTypeMediaClick,
  });

  const postTypeId = postData.post_type;
  const postMediaUploading =
    imageSelectorProps.imgUploading ||
    videoSelectorProps.videoUploading ||
    docSelectorProps.docUploading ||
    // obviously, uploading does not happen in case of offering
    false;
  let postMediaIsAdded: boolean | undefined = false;
  let disablePostTypeChange = false;
  if (!postMediaUploading) {
    switch (postTypeId) {
      case POST_TYPE_IDS_ENUM.IMAGE:
        postMediaIsAdded = postImageProps.showImages;
        break;
      case POST_TYPE_IDS_ENUM.DOCUMENT:
        postMediaIsAdded = postDocProps.showDocs;
        break;
      case POST_TYPE_IDS_ENUM.VIDEO:
        postMediaIsAdded = postVideoProps.showVideo;
        disablePostTypeChange = true;
        break;
      case POST_TYPE_IDS_ENUM.OFFERING:
        postMediaIsAdded = postOfferingProps.showOffering;
        disablePostTypeChange = true;
        break;
      case POST_TYPE_IDS_ENUM.TEXT:
      default:
        break;
    }
  }

  const disablePostTypeClick =
    disablePostTypeChangeProp || disablePostTypeChange || postMediaUploading;

  const onAddPostTypeMediaClick = (postTypeIdArg: number) => {
    if (disablePostTypeClick) return;
    switch (postTypeIdArg) {
      case POST_TYPE_IDS_ENUM.IMAGE:
        onAddPostTypeImageClick();
        break;
      case POST_TYPE_IDS_ENUM.DOCUMENT:
        onAddPostTypeDocClick();
        break;
      case POST_TYPE_IDS_ENUM.VIDEO:
        onAddPostTypeVideoClick();
        break;
      case POST_TYPE_IDS_ENUM.OFFERING:
        onAddPostTypeOfferingClick();
        break;
      case POST_TYPE_IDS_ENUM.TEXT:
      default:
        resetPostTypeToText();
        break;
    }
  };

  const handleChangeSharingType = (sharingType: number) => {
    setPostData((prev) => ({
      ...prev,
      metadata: {
        ...prev?.metadata,
        post_sharing_type: sharingType,
      },
    }));
  };

  const handleChangeAutoExpand = (checked: boolean) => {
    setPostData({
      ...postData,
      metadata: { ...postData.metadata, auto_expand: checked },
    });
  };

  useEffect(() => {
    if (isOpen) {
      if (typeof triggerPostTypeIdProp === "number") {
        setTriggerPostTypeId(triggerPostTypeIdProp);
        onAddPostTypeMediaClick(triggerPostTypeIdProp);
      }
    } else {
      setPostData(
        isCreator
          ? CREATOR_POST_UPDATE_PAYLOAD_DEFAULT_VALUE
          : POST_UPDATE_PAYLOAD_DEFAULT_VALUE,
      );
      setTriggerPostTypeId(undefined);
      if (postContentTextAreaRef.current) {
        postContentTextAreaRef.current.value = "";
      }
    }
  }, [isOpen]);

  return {
    // props used by the modal directly
    isOpen,
    onClose,
    postContentDefaultValue: postData.post_body,
    postContentTextAreaRef,
    postMediaUploading,
    postMediaIsAdded,
    postImageProps,
    postDocProps,
    postVideoProps,
    postOfferingProps,

    // props used by other components related to modal
    postData,
    onSaveButtonClick,
    onChangeSharingType: handleChangeSharingType,
    postTypeSelectorProps: {
      onPostTypeClick: onAddPostTypeMediaClick,
      disablePostTypeClick,
      postTypeId,
      postMediaIsAdded,
    },
    autoExpandPostComponentProps: {
      onChange: handleChangeAutoExpand,
      value: !!postData.metadata?.auto_expand,
    },
    imageSelectorProps,
    docSelectorProps,
    videoSelectorProps,
    offeringSelectorProps,
  };
};
