import { HTTP_STATUS_SUCCESS_CODES } from "./constants/apis";

/**
 * Checks if an HTTP request is successful based on the status code.
 *
 * @param {number} statusCode - The HTTP status code to check.
 * @returns {boolean} True if the status code indicates success, otherwise false.
 */
export function isRequestSuccessful(statusCode: number) {
  return Object.values(HTTP_STATUS_SUCCESS_CODES).includes(statusCode);
}

/**
 * Checks if a given string contains a space character.
 * @param {string} input - The string to check.
 * @returns {boolean} Returns true if the string contains a space, false otherwise.
 */
export const checkContainsSpaces = ({ input }) => {
  return input.trim().indexOf(" ") > -1;
};

/**
 * Extracts plain text content from an HTML string.
 *
 * @param {string} htmlString - The input HTML string.
 * @returns {string} - The extracted text content.
 */
export const extractTextContentFromHtml = (htmlString: string): string => {
  const parser = new DOMParser();
  const doc = parser?.parseFromString(htmlString, "text/html");
  return doc.body.textContent || "";
};
